import React from "react";
import "./Volunteer.css";
import { Badge } from "@chakra-ui/layout";
import { useParams } from "react-router-dom";
import { useEffect } from "react";
import { dataRef, db } from "./Firebase";
import firebase from "firebase/compat/app";
import { useState } from "react";
import { doc, getDoc } from "@firebase/firestore";

function Volunteer() {
  const idArr = window.location.pathname.split("/");
  const id = idArr[idArr.length - 1];
  const [obj, setObj] = useState({});
  useEffect(() => {
    const docRef = doc(db, "volunteers", id);
    const docSnap = getDoc(docRef)
      .then((e) => setObj(e.data()))
      .then();
  }, []);
  let img;
  if (obj.url) {
    img = obj.url;
  } else {
    img = "./no-pic.png";
  }
  console.log(obj);
  return (
    <div className="Volunteer">
      <img src={img} alt="لا توجد صورة" className="VolunteerImg" />

      <div
        className="VolunteerDetails"
        style={{ width: "100%", padding: "20px", fontSize: "20px" }}
      >
        <h1 className="VolunteerDetailsH1">{obj.fullName}</h1>
        <p className="detailDetails" style={{ paddingBottom: "20px" }}>
          {obj.details}
        </p>
        <hr />
        <p style={{ padding: "10px 0" }}>نوع الحالة : متطوع</p>

        <p style={{ padding: "10px 0" }}>الولاية : {obj.state}</p>
        <p style={{ padding: "10px 0" }}>المحلية : {obj.local}</p>
        <p style={{ padding: "10px 0" }}>حي : {obj.area}</p>
        <p style={{ padding: "10px 0" }}>العنوان : {obj.address}</p>
        <p style={{ padding: "10px 0" }}>رقم الهاتف : {obj.phone}</p>
        <p style={{ padding: "10px 0" }}>تطوع في المساعده بـ : {obj.help}</p>
      </div>
    </div>
  );
}

export default Volunteer;
