import React from "react";
import "./NeededCase.css";
import { Badge } from "@chakra-ui/layout";
import { doc, getDoc } from "@firebase/firestore";
import { db } from "./Firebase";
import { useState } from "react";
import { useEffect } from "react";
function GivingCase() {
  const idArr = window.location.pathname.split("/");
  const id = idArr[idArr.length - 1];
  const [obj, setObj] = useState({});
  useEffect(() => {
    const docRef = doc(db, "cases", id);
    const docSnap = getDoc(docRef)
      .then((e) => setObj(e.data()))
      .then();
  }, []);

  let img;
  if (obj.url) {
    img = obj.url;
  } else {
    img = "..//no-pic.png";
  }
  return (
    <div className="NeededCase">
      <img src={img} alt="لا توجد صورة" className="NeededCaseImg" />

      <div
        className="NeededCaseDetails"
        style={{ width: "100%", padding: "20px", fontSize: "20px" }}
      >
        {obj.isUrgent === "عاجل" && (
          <Badge colorScheme="red" className="NeededCaseBadge">
            عاجل
          </Badge>
        )}
        <h1 className="NeededCaseDetailsH1">{obj.title}</h1>
        <p className="detailDetails" style={{ paddingBottom: "20px" }}>
          {obj.details}
        </p>
        <hr />
        <p style={{ padding: "10px 0" }}>نوع الحالة : {obj.type}</p>

        <p style={{ padding: "10px 0" }}>الولاية : {obj.state}</p>
        <p style={{ padding: "10px 0" }}>المحلية : {obj.local}</p>
        <p style={{ padding: "10px 0" }}>حي : {obj.area}</p>
        <p style={{ padding: "10px 0" }}>العنوان : {obj.address}</p>
        <p style={{ padding: "10px 0" }}>امان المنطقة : {obj.isSave}</p>
        <p style={{ padding: "10px 0" }}>استعجال الحالة : {obj.isUrgent}</p>
        <p style={{ padding: "10px 0" }}>رقم الهاتف : {obj.phone}</p>
        <p style={{ padding: "10px 0" }}> الاحتياج : {obj.caseNeed}</p>
      </div>
    </div>
  );
}

export default GivingCase;
