import React, { useEffect, useState } from "react";
import "./NeededCases.css";
import { Button, Img } from "@chakra-ui/react";
import { Badge } from "@chakra-ui/react";
import { Select } from "@chakra-ui/react";
import { Link } from "@chakra-ui/react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { collection, getDocs, orderBy } from "@firebase/firestore";
import Cardd from "./components/Cardd";
import { db } from "./Firebase";
import { imageListClasses } from "@mui/material";
import { Pagination } from "antd";

function Cases() {
  const navigate = useNavigate();
  const [stateFilter, setStateFilter] = useState(null);
  const [needFilter, setNeedFilter] = useState(null);
  const [typeFilter, setTyperFilter] = useState(null);
  const [timeFilter, setTimeFilter] = useState("الاحدث");
  const [volunteers, setVolunteers] = useState([]);
  useEffect(() => {
    async function fetchVolunteers() {
      const collectionRef = collection(db, "cases");
      const querySnapshot = await getDocs(
        collectionRef,
        orderBy("timestamp", "asc")
      );

      const volunteers = [];
      querySnapshot.docs.map((doc) => {
        console.log(doc._key);
        volunteers.push({ id: doc.id, ...doc.data() });
      });
      setVolunteers(volunteers);
    }

    fetchVolunteers();
  }, []);

  // handel state
  const [currentPage, setCurrentPage] = useState(1);

  function handelFilter() {
    let dataAfterFilter = volunteers;
    const pageSize = 5;
    if (stateFilter) {
      dataAfterFilter = dataAfterFilter.filter((e) => e.state === stateFilter);
    }
    if (needFilter) {
      dataAfterFilter = dataAfterFilter.filter(
        (e) => e.caseNeed === needFilter
      );
    }
    if (typeFilter) {
      dataAfterFilter = dataAfterFilter.filter((e) => e.type === typeFilter);
    }
    if (timeFilter === "الاقدم") {
      dataAfterFilter = dataAfterFilter.sort(
        (a, b) => a.timestamp - b.timestamp
      );
    } else if (timeFilter === "الاحدث") {
      dataAfterFilter = dataAfterFilter
        .sort((a, b) => a.timestamp - b.timestamp)
        .reverse();
    }
    const indexOfLastItem = currentPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentData = dataAfterFilter.slice(
      indexOfFirstItem,
      indexOfLastItem
    );
    console.log(currentData);
    return (
      <>
        {currentData.length > 0 ? (
          currentData.map((ele) => (
            <Cardd
              title={ele.title}
              details={ele.details}
              help={ele.help || ele.caseNeed}
              state={ele.state}
              url={ele.url}
              Urgent={ele.isUrgent}
              type={ele.type}
              key={ele.id}
              navigate={ele.id}
              data={ele}
              ttype={"case"}
            />
          ))
        ) : (
          <h2>لا توجد بيانات</h2>
        )}
        <Pagination
          onChange={(e) => setCurrentPage(e)}
          pageSize={pageSize}
          total={dataAfterFilter.length}
          current={currentPage}
          showPrevNextJumpers={true}
        />
      </>
    );
  }
  // }

  return (
    <div className="CasesColumn">
      <div className="Filters">
        <Select
          placeholder="حدد نوع الاحتياج/الفائض"
          className="selectorAddNew"
          onChange={(e) => setNeedFilter(e.target.value)}
        >
          <option value="كهرباء">كهرباء</option>
          <option value="ماء">ماء</option>
          <option value="رصيد">رصيد</option>
          <option value="غاز">غاز</option>
          <option value="انترنت">انترنت</option>
          <option value="سكن">سكن</option>
          <option value="مواد غذائية">مواد غذائية</option>
          <option value="أدوية">أدوية</option>
          <option value="ترحيل">ترحيل</option>
          <option value="كاش">كاش</option>
          <option value="كادر طبي">كادر طبي</option>
          <option value="مركز صحي">مركز صحي</option>
          <option value="اسعاف">اسعاف</option>
          <option value="تنقل بين الولايات">تنقل بين الولايات</option>
          <option value="اتصال لاقارب">اتصال لاقارب</option>
          <option value="اكسجين">اكسجين</option>
          <option value="تبرع دم">تبرع دم</option>
          <option value="أخرى">أخرى</option>
        </Select>
        <Select
          placeholder="حدد نوع الحالة"
          className="selectorAddNew"
          onChange={(e) => setTyperFilter(e.target.value)}
        >
          <option value="احتياج">احتياج</option>
          <option value="فائض">فائض</option>
        </Select>
        <Select
          placeholder="حدد ترتيب الوقت"
          className="selectorAddNew"
          onChange={(e) => setTimeFilter(e.target.value)}
        >
          <option value="الاقدم">الاقدم اولا</option>
          <option value="الاحدث" selected>
            الاحدث اولا
          </option>
        </Select>
        <Select
          placeholder="حدد اسم الولاية"
          className="selectorAddNew"
          onChange={(e) => {
            setStateFilter(e.target.value);
            console.log(e.target.value);
          }}
        >
          <option value="الخرطوم">ولاية الخرطوم</option>
          <option value="نهر النيل">ولاية نهر النيل</option>
          <option value="الجزيرة">ولاية الجزيرة</option>
          <option value="القضارف">ولاية القضارف</option>
          <option value="البحر الاحمر">ولاية البحر الاحمر</option>
          <option value="كسلا">ولاية كسلا</option>
          <option value="جنوب دارفور">ولاية جنوب دارفور</option>
          <option value="نهر النيل الابيض">ولاية نهر النيل الابيض</option>
          <option value="غرب كردفان">ولاية غرب كردفان</option>
          <option value="نهر النيل الازرق">ولاية نهر النيل الازرق</option>
          <option value="شمال كردفان">ولاية شمال كردفان</option>
          <option value="غرب دارفور">ولاية غرب دارفور</option>
          <option value="شمال دارفور">ولاية شمال دارفور</option>
          <option value="شرق دارفور">ولاية شرق دارفور</option>
          <option value="وسط دارفور">ولاية وسط دارفور</option>
          <option value="سنار">ولاية سنار</option>
          <option value="جنوب كردفان">ولاية جنوب كردفان</option>
        </Select>
      </div>
      <div className="Cases">{handelFilter()}</div>
    </div>
  );
}

export default Cases;
