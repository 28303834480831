import React from "react";
import "./AddCase.css";
import { useState, useEffect } from "react";
import { FormControl } from "@chakra-ui/react";
import ReCAPTCHA from "react-google-recaptcha";
import { Select } from "@chakra-ui/react";
import { Input } from "@chakra-ui/react";
import { Textarea } from "@chakra-ui/react";
import { addDoc, collection, Timestamp } from "@firebase/firestore";
import { db, storage } from "./Firebase";
import { getDownloadURL, ref, uploadBytes } from "@firebase/storage";
import firebase from "firebase/compat/app";
import { useNavigate } from "react-router-dom";

function AddCase() {
  const [selectedImage, setSelectedImage] = useState(null);
  const [selected, setSelected] = React.useState("");
  const changeSelectOptionHandler = (event) => {
    setSelected(event.target.value);
  };
  const nav = useNavigate();

  /// data that i will send
  const [caseType, setCaseType] = useState("");
  // State
  //
  const [caseNeed, setCaseNeed] = useState("");
  const [local, setlocal] = useState("");
  const [area, setArea] = useState("");
  const [address, setAddress] = useState("");
  const [isUrgent, setIsUrgent] = useState("");
  const [isSave, setIsSave] = useState("");
  const [phone, setphone] = useState("");
  const [details, setDetails] = useState("");
  const [captcha, setCaptcha] = useState(false);
  const [title, setTitle] = useState();
  // collection in firebase

  const usersRef = collection(db, "cases");

  ///////////////////
  let [image, setImage] = useState(null);
  let [imageName, setImageName] = useState(null);

  console.log({
    caseType,
    caseNeed,
    selected, //الولايه
    local,
    area,
    address,
    isUrgent,
    isSave,
    phone,
    details,
    image,
  });

  // handel type of img
  let types = ["image/png", "image/jpeg", "image/jpg"];
  function volunteerImageHandel(e) {
    let image = e;
    if (image && types.includes(image.type)) {
      setImageName(image.name);
      setImage(image);
    } else {
      errror("الرجاء ادخال صوره بامتداد ('png' او 'jpeg' او 'jpg')");
    }
  }

  // Error popout

  let errror = function (text) {
    let div = document.createElement("div");
    div.classList.add("err");
    div.appendChild(document.createTextNode(text));
    document.body.appendChild(div);
    setTimeout(() => {
      div.remove();
    }, 10000);
  };

  // submit
  let submit = async function (e) {
    let spinner = document.querySelector(".spinner");
    spinner.classList.add("on");

    if (
      caseType.length > 0 &&
      caseNeed.length > 0 &&
      details.length > 0 &&
      phone.length > 0 &&
      isSave.length > 0 &&
      isUrgent.length > 0 &&
      address.length > 0 &&
      area.length > 0 &&
      selected.length > 0 &&
      title.length > 0
    ) {
      const storageRef = ref(storage, `cases-image/${imageName}`);
      if (image) {
        await uploadBytes(storageRef, image).then((snapshot) => {
          const now = Date.now();
          spinner.classList.add("on");
          getDownloadURL(storageRef).then((url) =>
            addDoc(usersRef, {
              type: caseType,
              caseNeed: caseNeed,
              state: selected,
              local: local,
              area: area,
              address: address,
              isUrgent: isUrgent,
              isSave: isSave,
              phone: phone,
              url: url,
              details: details,
              title: title,
              timestamp: now,
            })
          );
        });
      } else {
        const now = Date.now();

        await addDoc(usersRef, {
          type: caseType,
          caseNeed: caseNeed,
          state: selected,
          local: local,
          area: area,
          address: address,
          isUrgent: isUrgent,
          isSave: isSave,
          phone: phone,
          details: details,
          title: title,
          timestamp: now,
        });
      }
      nav("/");
    } else {
      errror("رجاء ملئ كل الحقول المطلوبة");
    }
  };
  /////////////////////////////////////////////
  // Use recaptcha Google
  const [verfied, setVerfied] = useState(false);
  function onChange(value) {
    console.log("Captcha value:", value);
  }

  // States & Local districts
  const Khartoum = [
    "كررى",
    "امبدة",
    "بحري",
    "شرق النيل",
    "امدرمان",
    "جبل اولياء",
    "الخرطوم",
    "دارفور",
  ];

  const NileRiver = ["الدامر", "عطبرة", "شندي", "المتمة", "بربر", "أبو حمد"];

  const Algazera = [
    "الكاملين",
    "ود مدني",
    "24 القرشي",
    "شرق الجزيرة",
    "الحصاحيصا",
    "ام القرى",
    "مدني الكبرى",
    "المناقل",
    "جنوب الجزيرة",
    "رفاعة",
  ];

  const Gedaref = [
    "القضارف",
    "الرهد",
    "وسط القضارف",
    "الفاو",
    "الفشقة",
    "البطانة",
    "القلابات الشرقية",
    "القلابات الغربية",
    "قلع النحل",
    "القريشة",
    "باسندا",
  ];

  const RedSea = [
    "بورسودان",
    "سواكن",
    "جبيت المعادن",
    "جبيت سكة حديد",
    "اربعات",
    "حلايب",
    "طوكر",
  ];

  const NorthSudan = ["عبري", "حلفا القديمة", "كرمة", "دنقلا"];

  const Kassala = [
    "كسلا",
    "خشم القربة",
    "تلكوك",
    "جنوب القاش (أروما)",
    "شمال الدلتا",
    "ود الحليو",
    "حلفا الجديدة",
    "نهر عطبرة",
    "ريف كسلا",
  ];

  const SouthDarfur = [
    "نيالا",
    "رهيد البردى",
    "الضعين",
    "عد الفرسان",
    "مرشنج",
    "شطاية",
    "دمسو",
    "ام دافوق",
    "الردوم",
    "السنطة",
    "نتيقة",
    "كبم",
    "كتيلا",
    "قريضة",
    "شرق الجبل",
    "الوحدة",
    "بليل",
    "السلام",
    "برام",
    "تلس",
    "كأس",
    "نيالا شمال",
    "نيالا جنوب",
  ];

  const WhiteNileRiver = [
    "كوستي",
    "ربك",
    "الكوة",
    "الدويم",
    "القطينة",
    "تندلتي",
    "الجبلين",
    "الجزيرة أبا",
    "الشوال",
    "نعيمة",
    "كنانة",
    "ام جر",
    "شبشة",
    "الصوفي",
    "الجمالاب",
    "الدرادر",
    "الهشابة",
    "قلى",
    "السلام",
    "ام رمته",
  ];

  const WestKordofan = [
    "الميرم",
    "الدبب",
    "الأضية",
    "الخوى",
    "غبيش",
    "ابو زبد",
    "السنوط",
    "النهود",
    "كيلك",
    "ودبندا",
    "لقاوة",
    "السلام",
    "بابنوسة",
    "المجلد",
    "أبيي",
  ];

  const BlueNileRiver = [
    "الدمازين",
    "التضامن",
    "الروصيرص",
    "باو",
    "الكرمك",
    "ود الماحي",
  ];

  const NorthKordofan = [
    "الرهد",
    "ام دم",
    "غرب بارا",
    "جبرة الشيخ",
    "ام روابه",
    "سودري",
    "شيكان",
  ];

  const WestDarfur = [
    "الجنينة",
    "كلبس",
    "بيضة",
    "هبلية",
    "سربا",
    "كرينك",
    "جبل مون",
    "فوربرنقا",
    "زالنجي",
  ];

  const NorthDarfur = [
    "الفاشر",
    "ام كدادة",
    "كتم",
    "الواحة",
    "اللعيت",
    "كلمندو",
    "مليط",
    "الكومة",
    "المالحة",
    "دار السلام",
    "كبكابية",
    "سرف عمرة",
    "السريف",
    "الطينة",
    "امبرو",
    "كرنوي",
    "طويلة",
    "طويشة",
  ];

  const EastDarfur = [
    "الضعين",
    "بحر العرب",
    "الفردوس",
    "ابوكارنكا",
    "شعيرية",
    "عديلة",
    "ابو جابرة",
    "ياسين",
    "عسلاية",
  ];

  const CentralDarfur = [
    "زالنجي ازوم",
    "قارسلا ",
    "نرتتي",
    "روكورو",
    "قولو",
    "بندس",
    "مكجر",
    "ام دخن",
    "وادي صالح",
    "غرب جبل مرة",
    "شمال جبل مرة",
    "وسط جبل مرة",
    "بندسي",
  ];

  const Sennar = [
    "الدالي والمزموم",
    "شرق سنار",
    "سنار",
    "ابو حجار",
    "السوكي",
    "الدندر",
    "سنجة",
  ];

  const SouthKordofan = [
    "الليري",
    "ابو كرشولا",
    "التضامن",
    "ام دورين",
    "البرام",
    "هبيلا",
    "الريف الشرقي",
    "تلودي",
    "هيبان",
    "دلامي",
    "القوز",
    "الدلنج",
    "رشاد",
    "ابو جبيهة",
    "العاسية",
    "كادوقلي",
    "السلام",
    "لقاوة",
    "كيلك",
    "السنوط",
    "الريف الشرقي",
    "البرام",
    "قدير",
    "هيبان",
  ];

  /** Type variable to store different array for different dropdown */
  let type = null;

  /** This will be used to create set of options that user will see */
  let options = null;

  /** Setting Type variable according to dropdown */
  if (selected === "الخرطوم") {
    type = Khartoum;
  } else if (selected === "نهر النيل") {
    type = NileRiver;
  } else if (selected === "الجزيرة") {
    type = Algazera;
  } else if (selected === "القضارف") {
    type = Gedaref;
  } else if (selected === "البحر الاحمر") {
    type = RedSea;
  } else if (selected === "كسلا") {
    type = Kassala;
  } else if (selected === "جنوب دارفور") {
    type = SouthDarfur;
  } else if (selected === "نهر النيل الابيض") {
    type = WhiteNileRiver;
  } else if (selected === "غرب كردفان") {
    type = WestKordofan;
  } else if (selected === "نهر النيل الازرق") {
    type = BlueNileRiver;
  } else if (selected === "شمال كردفان") {
    type = NorthKordofan;
  } else if (selected === "غرب دارفور") {
    type = WestDarfur;
  } else if (selected === "شمال دارفور") {
    type = NorthDarfur;
  } else if (selected === "شرق دارفور") {
    type = EastDarfur;
  } else if (selected === "وسط دارفور") {
    type = CentralDarfur;
  } else if (selected === "سنار") {
    type = Sennar;
  } else if (selected === "جنوب كردفان") {
    type = SouthKordofan;
  }

  if (type) {
    options = type.map((el) => <option key={el}>{el}</option>);
  }
  console.log(image);
  return (
    <div className="caseContainer">
      <h4>
        اضافة حالة جديدة <br />
        (ادخل بيانات صحيحة من فضلك)
      </h4>
      <FormControl className="inputForm">
        <label className="lableClass"> * ادخل عنوان النص</label>
        <Input
          onChange={(e) => setTitle(e.target.value)}
          placeholder="مثال: احتاج مياة بشكل عاجل"
          className="inputAddNew"
        />
      </FormControl>
      <label className="lableClass" style={{ marginTop: "20px" }}>
        {" "}
        * نوع الحالة
      </label>
      <Select
        placeholder="حدد نوع الحالة"
        onChange={(e) => setCaseType(e.target.value)}
        className="selectorAddNew"
      >
        <option value="احتياج">احتياج</option>
        <option value="فائض">فائض</option>
      </Select>
      <br />
      <label className="lableClass"> * نوع الاحتياج/الفائض؟</label>
      <Select
        placeholder="حدد نوع الاحتياج/الفائض"
        className="selectorAddNew"
        onChange={(e) => setCaseNeed(e.target.value)}
      >
        <option value="كهرباء">كهرباء</option>
        <option value="ماء">ماء</option>
        <option value="رصيد">رصيد</option>
        <option value="غاز">غاز</option>
        <option value="انترنت">انترنت</option>
        <option value="سكن">سكن</option>
        <option value="مواد غذائية">مواد غذائية</option>
        <option value="أدوية">أدوية</option>
        <option value="ترحيل">ترحيل</option>
        <option value="كاش">كاش</option>
        <option value="كادر طبي">كادر طبي</option>
        <option value="مركز صحي">مركز صحي</option>
        <option value="اسعاف">اسعاف</option>
        <option value="تنقل بين الولايات">تنقل بين الولايات</option>
        <option value="اتصال لاقارب">اتصال لاقارب</option>
        <option value="اكسجين">اكسجين</option>
        <option value="تبرع دم">تبرع دم</option>
        <option value="أخرى">أخرى</option>
      </Select>

      <br />
      <label className="lableClass"> * تحديد الولاية</label>
      {}
      <Select
        placeholder="تحديد الولاية"
        className="selectorAddNew"
        onChange={changeSelectOptionHandler}
      >
        <option value="الخرطوم">ولاية الخرطوم</option>
        <option value="نهر النيل">ولاية نهر النيل</option>
        <option value="الجزيرة">ولاية الجزيرة</option>
        <option value="القضارف">ولاية القضارف</option>
        <option value="البحر الاحمر">ولاية البحر الاحمر</option>
        <option value="كسلا">ولاية كسلا</option>
        <option value="جنوب دارفور">ولاية جنوب دارفور</option>
        <option value="نهر النيل الابيض">ولاية نهر النيل الابيض</option>
        <option value="غرب كردفان">ولاية غرب كردفان</option>
        <option value="نهر النيل الازرق">ولاية نهر النيل الازرق</option>
        <option value="شمال كردفان">ولاية شمال كردفان</option>
        <option value="غرب دارفور">ولاية غرب دارفور</option>
        <option value="شمال دارفور">ولاية شمال دارفور</option>
        <option value="شرق دارفور">ولاية شرق دارفور</option>
        <option value="وسط دارفور">ولاية وسط دارفور</option>
        <option value="سنار">ولاية سنار</option>
        <option value="جنوب كردفان">ولاية جنوب كردفان</option>
      </Select>
      <br />
      <label className="lableClass"> ( اختياري ) تحديد المحلية</label>
      <Select
        placeholder="تحديد المحلية"
        className="selectorAddNew"
        onChange={(e) => setlocal(e.target.value)}
      >
        {options}
      </Select>
      <br />
      <FormControl className="inputForm">
        <label className="lableClass"> * ادخل اسم الحي</label>
        <Input
          onChange={(e) => setArea(e.target.value)}
          placeholder="مثال: حي النصر, شرق النيل"
          className="inputAddNew"
        />
      </FormControl>
      <br />
      <FormControl className="inputForm">
        <label className="lableClass"> * ادخل العنوان</label>
        <Input
          onChange={(e) => setAddress(e.target.value)}
          placeholder="مثال: منزل لونه أحمر بجوار مسجد أبو بكر الصديق"
          className="inputAddNew"
        />
      </FormControl>
      <br />
      <label className="lableClass"> * استلام/تسليم الحاجة</label>
      <Select
        placeholder="استلام/تسليم الحاجة"
        className="selectorAddNew"
        onChange={(e) => setIsUrgent(e.target.value)}
      >
        <option value="غير عاجل">غير عاجل</option>
        <option value="عاجل">عاجل</option>
      </Select>
      <br />
      <label className="lableClass"> * الوضع الامني</label>
      <Select
        placeholder="حدد نوع الحالة"
        className="selectorAddNew"
        onChange={(e) => setIsSave(e.target.value)}
      >
        <option value="آمن">آمن</option>
        <option value="غير آمن">غير آمن</option>
        <option value="لا أعلم">لا أعلم</option>
      </Select>
      <br />
      <FormControl className="inputForm">
        <label className="lableClass"> * ادخل رقم الهاتف</label>
        <Input
          placeholder="مثال: *****091185"
          className="inputAddNew"
          onChange={(e) => setphone(e.target.value)}
        />
      </FormControl>
      <br />
      <label className="lableClass"> ارفاق صورة ( اختياري ) </label>
      {selectedImage && (
        <div>
          <img
            alt="not found"
            width={"250px"}
            src={URL.createObjectURL(selectedImage)}
          />
          <br />
          <button
            onClick={() => {
              setImage(null);
              setSelectedImage(null);
            }}
          >
            حذف الصورة
          </button>
        </div>
      )}

      <br />
      <br />

      <input
        className="UploadImage"
        type="file"
        name="myImage"
        onChange={(event) => {
          volunteerImageHandel(event.target.files[0]);
          setSelectedImage(event.target.files[0]);
        }}
      />
      <br />
      <br />
      <label className="lableClass"> * تفاصيل الحالة</label>
      <Textarea
        placeholder="اكتب التفاصيل هنا"
        className="textAreaAddNew"
        onChange={(e) => setDetails(e.target.value)}
      />

      <button variant="ghost" className="submitButton" onClick={submit}>
        <span className="spinner"></span>
        ارسال
      </button>
    </div>
  );
}

export default AddCase;
