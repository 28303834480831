import { TriangleDownIcon, TriangleUpIcon } from "@chakra-ui/icons";
import { Badge } from "@chakra-ui/layout";
import { useContext } from "react";
import { Link } from "react-router-dom";
import "./Cardd.css";

export default function Cardd(props) {
  let navto;
  let local;
  if (props.ttype === "vol") {
    navto = `/Volunteer/${props.navigate}`;
    local = `Volunteer`;
  }
  if (props.type === "احتياج") {
    navto = `/NeededCase/${props.navigate}`;
    local = "NeededCase";
  }
  if (props.type === "فائض") {
    navto = `/GivingCase/${props.navigate}`;
    local = "GivingCase";
  }

  var descrip = props.details;
  if(descrip.length > 35) descrip = descrip.substring(0,50);

  var title = props.details;
  if(title.length > 20) title = title.substring(0,20);


  return (
    <Link
      onClick={() => {
        navigator(navto);
      }}
      to={navto}
      className="Link"
    >
      <div className="Volunteers">
        <div className="VolunteersDetails">
          <h1 className="VolunteersDetailsH1">..{title} </h1>
          <p className="black" >..{descrip}</p>
          <div
            className="VolunteersDetailsBadges"
            style={{ padding: "10px 0", marginTop: "auto" }}
          >
            <Badge className="VolunteersDetailsBadge">
              {props.state && props.state}
            </Badge>
            <Badge className="VolunteersDetailsBadge">{props.help}</Badge>
          </div>
        </div>
        <img
          src={props.url ? props.url : "./no-pic.png"}
          alt="لا توجد صورة"
          className="VolunteersImg"
        />
        {props.type === "احتياج" && (
          <span
            className="GivingCasesBadge NeedDarkLight two"
            colorScheme="red"
            style={{
              right: "100px",
              width: "75px",
              backgroundColor: "#fed7d7",
            }}
          >
            <h4>احتياج</h4>
          </span>
        )}
        {props.type == "فائض" && (
          <span
            className="GivingCasesBadge LightDark two"
            style={{
              right: "100px",
              width: "75px",
              backgroundColor: "#edf2f7",
            }}
          >
            <h4>فائض</h4>
          </span>
        )}
        {props.Urgent === "عاجل" && (
          <Badge colorScheme="red" className="GivingCasesBadge">
            عاجل
          </Badge>
        )}
        {props.Urgent === "غير عاجل" && (
          <Badge colorScheme="gray" className="GivingCasesBadge">
            غير عاجل
          </Badge>
        )}
      </div>
    </Link>
  );
}
