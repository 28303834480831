import React, { useEffect, useState } from "react";
import "./Volunteers.css";
import { Badge, Card } from "@chakra-ui/react";
import { Select } from "@chakra-ui/react";
import { Link } from "@chakra-ui/react";
import { Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Cardd from "./components/Cardd";
import {
  QuerySnapshot,
  collection,
  doc,
  getDoc,
  getDocs,
  orderBy,
} from "@firebase/firestore";

import { db } from "./Firebase";

import { Pagination } from "antd";
export default function Volunteers() {
  // pages
  const [total, Settotal] = useState("");
  const [page, setPage] = useState(1);
  const [postPrevPage, setPostPrevPage] = useState(10);

  const navigate = useNavigate();
  const [volunteers, setVolunteers] = useState([]);
  const [stateFilter, setStateFilter] = useState(null);
  const [needFilter, setNeedFilter] = useState(null);
  const [timeFilter, setTimeFilter] = useState("الاحدث");

  useEffect(() => {
    async function fetchVolunteers() {
      const collectionRef = collection(db, "volunteers");
      const querySnapshot = await getDocs(
        collectionRef,
        orderBy("timestamp", "asc")
      );
      const volunteers = [];

      querySnapshot.docs.map((doc) =>
        volunteers.push({ id: doc.id, ...doc.data() })
      );
      setVolunteers(volunteers);
    }

    fetchVolunteers();
  }, []);
  // handel state

  const [currentPage, setCurrentPage] = useState(1);
  function handelFilter() {
    const pageSize = 5;

    let dataAfterFilter = volunteers;
    if (stateFilter) {
      dataAfterFilter = dataAfterFilter.filter((e) => e.state === stateFilter);
    }
    if (needFilter) {
      dataAfterFilter = dataAfterFilter.filter((e) => e.help === needFilter);
    }
    if (timeFilter === "الاقدم") {
      dataAfterFilter = dataAfterFilter.sort(
        (a, b) => a.timestamp - b.timestamp
      );
    } else if (timeFilter === "الاحدث") {
      dataAfterFilter = dataAfterFilter
        .sort((a, b) => a.timestamp - b.timestamp)
        .reverse();
    }

    const indexOfLastItem = currentPage * pageSize;
    const indexOfFirstItem = indexOfLastItem - pageSize;
    const currentData = dataAfterFilter.slice(
      indexOfFirstItem,
      indexOfLastItem
    );
    console.log(currentData);
    return (
      <>
        {currentData.length > 0 ? (
          currentData.map((ele) => (
            <Cardd
              key={ele.id}
              title={ele.fullName}
              details={ele.details}
              help={ele.help}
              state={ele.state}
              navigate={ele.id}
              url={ele.url}
              ttype={"vol"}
              data={ele}
            />
          ))
        ) : (
          <h2>لا توجد بيانات</h2>
        )}
        <Pagination
          onChange={(e) => setCurrentPage(e)}
          pageSize={pageSize}
          total={dataAfterFilter.length}
          current={currentPage}
          className="PaginationDark"
        />
      </>
    );
  }

  // setCardds(carrrds);
  return (
    <div className="CasesColumn">
      <div className="Filters">
        <Select
          placeholder="حدد نوع الاحتياج/الفائض"
          className="selectorAddNew"
          onChange={(e) => setNeedFilter(e.target.value)}
        >
          <option value="كهرباء">كهرباء</option>
          <option value="ماء">ماء</option>
          <option value="رصيد">رصيد</option>
          <option value="غاز">غاز</option>
          <option value="انترنت">انترنت</option>
          <option value="سكن">سكن</option>
          <option value="مواد غذائية">مواد غذائية</option>
          <option value="أدوية">أدوية</option>
          <option value="ترحيل">ترحيل</option>
          <option value="كاش">كاش</option>
          <option value="كادر طبي">كادر طبي</option>
          <option value="مركز صحي">مركز صحي</option>
          <option value="اسعاف">اسعاف</option>
          <option value="تنقل بين الولايات">تنقل بين الولايات</option>
          <option value="اتصال لاقارب">اتصال لاقارب</option>
          <option value="اكسجين">اكسجين</option>
          <option value="تبرع دم">تبرع دم</option>
          <option value="أخرى">أخرى</option>
        </Select>
        <Select
          placeholder="حدد ترتيب الوقت"
          className="selectorAddNew"
          onChange={(e) => setTimeFilter(e.target.value)}
        >
          <option value="الاقدم">الاقدم اولا</option>
          <option value="الاحدث" selected>
            الاحدث اولا
          </option>
        </Select>
        <Select
          placeholder="حدد اسم الولاية"
          className="selectorAddNew"
          onChange={(e) => setStateFilter(e.target.value)}
        >
          <option value="الخرطوم">ولاية الخرطوم</option>
          <option value="نهر النيل">ولاية نهر النيل</option>
          <option value="الجزيرة">ولاية الجزيرة</option>
          <option value="القضارف">ولاية القضارف</option>
          <option value="البحر الاحمر">ولاية البحر الاحمر</option>
          <option value="كسلا">ولاية كسلا</option>
          <option value="جنوب دارفور">ولاية جنوب دارفور</option>
          <option value="نهر النيل الابيض">ولاية نهر النيل الابيض</option>
          <option value="غرب كردفان">ولاية غرب كردفان</option>
          <option value="نهر النيل الازرق">ولاية نهر النيل الازرق</option>
          <option value="شمال كردفان">ولاية شمال كردفان</option>
          <option value="غرب دارفور">ولاية غرب دارفور</option>
          <option value="شمال دارفور">ولاية شمال دارفور</option>
          <option value="شرق دارفور">ولاية شرق دارفور</option>
          <option value="وسط دارفور">ولاية وسط دارفور</option>
          <option value="سنار">ولاية سنار</option>
          <option value="جنوب كردفان">ولاية جنوب كردفان</option>
        </Select>
      </div>
      <div className="Cases">{handelFilter()}</div>
    </div>
  );
}
